import React from 'react'
import Resturant from './component/basics/Resturant'
import UseState from './component/hooks/usestate'
import UseEffect from './component/hooks/useEffect'
import UseReducer from './component/hooks/UseReducer'

const App = () => {
  return (
    <Resturant/>
    // <UseState/>
    // <UseReducer/>
  )
}

export default App